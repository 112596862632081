<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-center justify-between filter-title"
          :class="{ 'font-weight-bold': isFilterApplied }"
          v-on="on"
          v-bind="attrs"
          >
          <div class="d-flex align-center flex-grow-1">
            <v-icon left>
              translate
            </v-icon>
            Language
          </div>
          <v-icon>
            expand_more
          </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none">
          Language
        </v-card-title>

        <v-card-text
          class="pt-md-3"
          :class="{ 'black--text': isThemeLight }"
        >
          <v-row>
            <!-- Audience Section -->
            <v-col
              v-for="kind in computedKinds"
              :key="kind"
              cols="12"
              :md="computedKinds.length === 1 ? 12 : 6"
            >
              <div class="mb-1 d-flex">
                <div class="d-flex align-center flex-grow-1">
                  <v-icon
                    :class="{ 'black--text': isThemeLight }"
                    class="mr-1"
                    left
                  >
                    {{ kind === 'audience' ? 'people' : 'volume_down' }}
                  </v-icon>

                  {{ capitalizeString(kind) }}
                </div>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="isThemeLight ? 'black' : null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>

                  <span>
                    {{ tooltipTexts[kind] }}
                  </span>
                </v-tooltip>
              </div>

              <v-autocomplete
                v-model="form[kind]"
                :items="searchItems"
                label="Languages"
                dense
                eager
                outlined
                :append-icon="null"
                return-object
                hide-details
                item-text="name"
                item-value="id"
                @change="submitForm(kind)"
              >
                <template v-slot:no-data>
                  <div class="text-center py-2">
                    No results found.
                  </div>
                </template>
              </v-autocomplete>

              <div v-if="appliedFiltersFor[kind].length" class="mt-3">
                <div
                  v-for="item in appliedFiltersFor[kind]"
                  :key="item.id"
                >
                  <div class="d-flex justify-space-between align-center pb-1 pt-3">
                    <div class="d-flex align-center">
                      <v-btn small icon @click="removeFilterItem(item)">
                        <v-icon small>
                          clear
                        </v-icon>
                      </v-btn>

                      {{ item.data.inputs.name }}
                    </div>

                    <weight-selector
                      v-if="kind === 'audience'"
                      v-model="weightInput[item.timestamp]"
                      @change="updateFilterItem(item)"
                    />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// Import helper function
import dataHelper from "@/helpers/dataHelper"
import filterConverter from "@/helpers/filterConverter"

const WeightSelector = () => import(/* webpackChunkName: "weight-selector" */ "@/blocks/common/selectors/WeightSelector.vue")

const originalForm = () => ({
  audience: null,
  influencer: null
})

const filterName = "language"

export default {
  name: "FilterLanguage",

  components: {
    WeightSelector
  },

  // Accept incoming data from parent
  props: {
    platform: {
      type: String,
      required: true
    }
  },

  data: () => ({
    form: originalForm(),

    shouldShowModal: false,
    searchQuery: {
      audience: "",
      influencer: ""
    },

    // The key would be the symbol for the applied filter in VueX state
    weightInput: {},

    languages: [],

    tooltipTexts: {
      audience: "Identify influencers by their audience language. We determine language of audience by analyzing captions of recent posts.",
      influencer: "Identify Influencers by language. We determine language of influencers by analyzing captions of recent posts."
    }
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["influencerDiscovery/findFiltersByType"](filterName)
    },

    appliedFiltersFor() {
      return {
        audience: this.appliedFilters.filter((item) => item.data.kind === "audience"),
        influencer: this.appliedFilters.filter((item) => item.data.kind === "influencer")
      }
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    },

    searchItems() {
      return this.languages.map((item) => ({ ...item, weight: 0.05 }))
    },

    /**
     * If this is not snapchat, let the user choose the audience
     *
     * @returns {Boolean}
     */
    shouldShowAudience() {
      return !["snapchat", "linkedin"].includes(this.platform)
    },

    /**
     * Get the filter types to be shown in the form
     *
     * @returns {Array}
     */
     computedKinds() {
      if (this.shouldShowAudience)
        return ['audience', 'influencer']
      else
        return ['influencer']
    },
  },

  validations: {
    form: {
      audience: {},
      influencer: {}
    }
  },

  methods: {
    /**
     * Takes an item and generates the text based on kind and weight
     */
    computeFilterText({ kind, name, weight }) {
      return `${capitalizeString(kind)} Language: ${name}` + (kind === 'audience' ? ` | >${weight * 100}%` : '')
    },

    /**
     * Push the data from either of the inputs to the filters array in Vuex Store
     *
     * @param {String} kind | Either "audience" or "influencer"
     */
    async submitForm(kind) {
      // Run validations
      await this.$v.form[kind].$touch()

      // If there's any error, stop further execution
      if (this.$v.form[kind].$anyError) return

      // Compute the API filter object
      const requestFilterData = {
        code: this.form[kind].code
      }

      // If the kind is audience, add the weight attribute
      if (kind === "audience") {
        requestFilterData.weight = this.form[kind].weight
      }

      // Save the filter
      this.$store.dispatch(
        "influencerDiscovery/replaceFilter",
        await filterConverter(
          kind === "audience" ? "audience_lang" : "lang",
          requestFilterData
        )
      )

      // reset the search query input
      this.searchQuery[kind] = ""
      // reset the form input to allow more values
      this.form[kind] = null
      // finally hide the modal
      this.shouldShowModal = false
    },

    /**
     * Update the value of already existing filter
     * Most probably it'd just be the weight attribute
     */
    async updateFilterItem(item) {
      // Compute the API
      const requestFilterData = {
        ...item.data.inputs.requestData,
        weight: this.weightInput[item.timestamp]
      }

      const updatedItem = {
        ...item,
        ...(await filterConverter(
          item.data.kind === "audience" ? "audience_lang" : "lang",
          requestFilterData
        ))
      }

      this.$store.dispatch("influencerDiscovery/updateFilter", updatedItem)
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("influencerDiscovery/removeFilter", item.id)
    }
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  async created() {
    // Fetch and store interests in the store
    this.languages = await dataHelper.loadData("languages.json")
  },

  /**
   * Before the component is destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Unload the data
    dataHelper.unloadData("languages.json")
  }
}
</script>
